@import '../ui/scss/_variables.scss';
@import '../ui/scss/_functions.scss';

:root {
	@include getColors(#EF3232, "primary");
	@include getColors(#CE9B44, "sec");
	@include getColors(#0092E4, "blue");
	@include getColors(#FF9820, "yellow");
	@include getColors(#31A043, "green");
	@include getColors(#EB5757, "red");
	@include getColors(#828A99, "icon");
	@include getColors(#302B44, "text");

	--bg1: #E9EAEC;
	--bg2: #F4F5F6;
	--bg3: #FFFFFF;

	--spacing: 4px;

	--radius-small: 4px;
	--radius-middle: 8px;
	--radius-large: 12px;

	--media-lg: #{$lg};
	--media-md: #{$md};
	--media-sm: #{$sm};

	--font-base: 'Inter', Arial, Helvetica, sans-serif;

	--zi-l1: 321;
	--zi-l2: 322;
	--zi-l3: 323;
	--zi-l4: 324;
	--zi-l5: 325;
	--zi-l6: 326;
	--zi-l7: 327;
	--zi-l8: 328;
	--zi-l9: 329;
}

[data-theme="dark"] {
	@include getColors(#ffffff, text);

	--bg1: #181818;
	--bg2: #282828;
	--bg3: #323232;
}

body {
	background:
		url("../img/bg.jpg") repeat-y top / 100% auto,
		var(--bg3);
	color: var(--color-text);
	min-height: 100vh;
	display: flex;
	flex-direction: column;


	* {
		font-family: var(--font-base);
	}

	&.menu-open {
		overflow: hidden;
	}
}

body:not(.loaded) {
	overflow: hidden;
}

section {}

.btn--contur {

	&:focus:not(:active) {
		background: linear-gradient(84.16deg, rgba(255, 227, 211, 0.3) 0%, rgba(209, 244, 242, 0) 87.85%), #302B44;
		background-clip: padding-box;
		border-color: rgba(48, 43, 68, 0);
		color: #fff;
	}

	@media(hover) {
		&:hover {
			background: linear-gradient(84.16deg, rgba(255, 227, 211, 0.3) 0%, rgba(209, 244, 242, 0) 87.85%), #302B44;
			background-clip: padding-box;
			border-color: rgba(48, 43, 68, 0);
			color: #fff;
		}
	}
}

.active-enabled {
	display: none !important;
}

.toggle-active,
.tab-active,
.active {
	.active-disabled {
		display: none !important;
	}

	.active-enabled {
		display: flex !important;
	}
}

.background {
	height: 100vh;
	width: 100%;
	position: relative;

	&-wrapper {
		overflow: hidden;
		pointer-events: none;
		height: 2000px;
		position: absolute;
		inset: 0;
	}

	&-item {
		position: absolute;
		border-radius: 50%;
		--duration: 5s;
		animation: bubbles var(--duration) infinite both;

		&--1 {
			width: calc(100% * 558 / 1920);
			height: calc(100% * 368 / 1080);
			right: calc(100% * (-72) / 1920);
			top: 0px;

			background: #FF4B47;
			opacity: 0.2;
			filter: blur(100px);
		}

		&--2 {
			width: calc(100% * 1381 / 1920);
			height: calc(100% * 445 / 1080);
			left: calc(100% * 740 / 1920);
			top: calc(100% * 176 / 1080);

			background: #FFF59D;
			opacity: 0.3;
			filter: blur(100px);
			transform: matrix(-0.87, -0.25, -0.62, 0.95, 0, 0);
			animation-delay: calc(var(--duration) / 4);
		}

		&--3 {
			width: calc(100% * 776 / 1920);
			height: calc(100% * 148 / 1080);
			left: calc(100% * 1100 / 1920);
			top: calc(100% * 630 / 1080);

			background: linear-gradient(91.07deg, #00A6FF -2.42%, #48FFBF 88.53%);
			opacity: 0.4;
			filter: blur(100px);
			transform: matrix(-0.88, -0.23, -0.58, 0.96, 0, 0);
			animation-delay: calc(2 * var(--duration) / 4);
		}

		&--4 {
			width: calc(100% * 708 / 1920);
			height: calc(100% * 468 / 1080);
			left: calc(100% * 635 / 1920);
			top: calc(100% * 562 / 1080);

			background: #FFA0A0;
			opacity: 0.2;
			filter: blur(100px);
			transform: matrix(-1, 0, 0, 1, 0, 0);
			animation-delay: calc(3 * var(--duration) / 4);
		}
	}
}

@keyframes bubbles {
	50% {
		opacity: .5;
	}
}

.fade-in {
	transition: .5s;

	&[data-scroll]:not(.is-inview) {
		opacity: 0;
	}
}

.fade-in-left {
	transition: .5s;

	&[data-scroll]:not(.is-inview) {
		opacity: 0;
		transform: translateX(-32px);
	}
}

.fade-in-down {
	transition: .5s;

	&[data-scroll]:not(.is-inview) {
		opacity: 0;
		transform: translateY(32px);
	}
}

.title {
	background: linear-gradient(270.31deg, rgba(117, 191, 243, 0.6) 0.13%, rgba(117, 191, 243, 0) 64.36%), linear-gradient(96.25deg, rgba(255, 97, 97, 0.6) 0%, rgba(255, 97, 97, 0) 54.94%), #D940A5;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-weight: 900;
	font-size: 60px;
	line-height: 1.1;
	text-align: center;

	@media (max-width:$lg) {
		font-size: 50px;
	}

	@media (max-width:$md) {
		font-size: 40px;
	}

	@media (max-width:$sm) {
		font-size: 32px;
	}
}

// header -=::=--=::=--=::=--=::=--=::=--=::=--=::=--=::=-
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 42px;

	@media (max-width:$md) {
		padding-top: 16px;
	}

	&-logo {
		@media (max-width:$sm) {
			width: 100px;
		}
	}

	&-sign {
		@media (max-width:$sm) {
			width: 100px;
		}
	}

	&-phone {
		@media (max-width:$sm) {
			width: 180px;
		}
	}
}

.dialog-form {
	display: flex;
	align-items: center;
	background: #FFFFFF;
	border-radius: 24px;
	padding: 16px;
	padding-right: 32px;
	width: 830px;
	max-width: 100%;

	@media(max-width:1023.98px) {
		padding: 32px;
		width: 600px;
	}

	&__img {
		border-radius: 24px;
		overflow: hidden;
		min-width: 410px;
		width: 410px;
		margin-right: 52px;

		@media(max-width:1023.98px) {
			display: none;
		}
	}

	&__content {
		flex-grow: 1;
	}

	&__title {
		font-weight: 600;
		font-size: 32px;
		margin-bottom: 0.8em;
	}
}

.started {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	text-align: center;
	padding-top: 286px;
	padding-bottom: 185px;
	// overflow      : hidden;

	@media (max-width:$md) {
		padding-bottom: 80px;
	}

	@media (max-width:$sm) {
		padding-top: 330px;
		margin-top: -44px;
		overflow: hidden;
	}

	@media (max-width:$xs) {
		padding-bottom: 32px;
		padding-top: 304px;
		overflow: hidden;
	}

	&-title {
		margin-bottom: 46px;

		@media (max-width:$sm) {
			margin-bottom: 20px;
		}
	}

	&-subtitle {
		font-weight: 400;
		font-size: 20px;

		@media (max-width:$lg) {
			font-size: 16px;
		}

		@media (max-width:$sm) {


			br {
				display: none;
			}
		}
	}

	&-img {
		position: absolute;
		animation: fly 6s ease infinite both;
		pointer-events: none;

		@media (max-width:$md) {
			width: 180px;
		}

		@media (max-width:$sm) {
			width: 120px;
		}

		&--1 {
			top: 280px;
			right: calc(50% + 538px);
			animation-delay: 1s;

			@media (max-width:$lg) {
				right: calc(50% + 300px);
			}

			@media (max-width:$md) {
				right: calc(50% + 100px);
				top: 100px;
			}

			@media (max-width:$sm) {
				right: calc(50% + 100px);
				top: 144px;
			}

			@media (max-width:$xs) {
				right: calc(50% + 50px);

			}
		}

		&--2 {
			top: 0;
			left: calc(50% - 165px);

			@media (max-width:$lg) {
				left: calc(50% - 90px);
			}

			@media (max-width:$sm) {
				left: calc(50% - 60px);
				top: 44px;
			}

			@media (max-width:$xs) {
				top: 54px;
			}
		}

		&--3 {
			top: 280px;
			left: calc(50% + 468px);
			animation-delay: 2s;

			@media (max-width:$lg) {
				left: calc(50% + 200px);
				top: 100px;
			}

			@media (max-width:$md) {
				left: calc(50% + 100px);
			}

			@media (max-width:$sm) {
				top: 164px;
				left: calc(50% + 100px);
			}

			@media (max-width:$xs) {
				left: calc(50% + 50px);

			}
		}
	}

	&--plug {
		flex-grow: 1;
		padding-bottom: 180px;

		@media (max-width:$sm) {
			padding-top: 220px;
			flex-grow: 0;
		}

	}

	&--plug &-img {
		&--1 {
			top: 280px;
			right: calc(50% + 450px);

			@media (max-width:1300px) {
				right: calc(50% + 350px);

			}

			@media (max-width:$md) {
				left: 20px;
				bottom: 20px;
				top: auto;
				right: auto;

			}
		}

		&--2 {
			top: -40px;
			left: calc(50% - 180px);

			@media (max-width:$md) {
				left: calc(50% - 90px);
				top: 60px;
			}

			@media (max-width:$sm) {
				left: calc(50% - 60px);
				top: 100px;
			}
		}

		&--3 {
			top: 320px;
			left: calc(50% + 420px);

			@media (max-width:1300px) {
				left: calc(50% + 320px);

			}

			@media (max-width:$md) {
				right: 20px;
				bottom: 20px;
				left: auto;
				top: auto;

			}
		}
	}

	&--plug &-title {
		@media (max-width:1300px) {
			font-size: 50px;
		}

		@media (max-width:$md) {
			font-size: 32px;
		}
	}

	&--plug &-subtitle {
		@media (max-width:1300px) {
			font-size: 16px;
		}

		@media (max-width:$md) {
			font-size: 14px;
		}
	}
}

.fact {
	overflow: hidden;

	@media(max-width:$lg) {
		padding-left: 15px;
		padding-right: 15px;
	}

	@media (max-width:$md) {
		padding: 0;
	}

	.swiper {
		overflow: visible;
		position: relative;
	}

	&-item {
		border-radius: 60px;
		padding: 80px;
		position: relative;
		overflow: hidden;
		height: 728px;

		@media(max-width:$lg) {
			height: 640px;
			padding: 60px 40px;
			border-radius: 40px;
		}

		@media (max-width:$md) {
			height: 100%;
			border-radius: 0;
			padding: 0;
			display: flex;
			flex-direction: column;

			br {
				display: none;
			}
		}

		&-content {
			position: relative;
			z-index: 2;

			@media (max-width:$md) {
				padding: 32px 15px;
			}
		}

		&-picture {
			@media (max-width:$md) {
				order: -1;
				height: 400px;
				position: relative;
				overflow: hidden;
			}
		}

		&-sign {
			font-weight: 700;
			font-size: 18px;
			margin-bottom: 32px;
			transition: .5s .2s;
			--hide-transform: translateY(20px);

			@media(max-width:$lg) {
				font-size: 16px;
			}

		}

		&-title {
			font-weight: 900;
			font-size: 64px;
			margin-bottom: 32px;
			transition: .5s .3s;
			--hide-transform: translateY(20px);

			@media(max-width:$lg) {
				font-size: 44px;
			}

			@media (max-width:$md) {
				font-size: 32px;
			}
		}

		&-desc {
			font-size: 18px;
			line-height: 1.5;
			transition: .5s .4s;
			--hide-transform: translateY(20px);

			@media(max-width:$lg) {
				font-size: 16px;
			}

			@media (max-width:$md) {
				font-size: 14px;
			}
		}

		&-img {
			position: absolute;
			--hide-transform: translateY(20px);

			img {
				max-height: 100%;
			}

			@media (max-width:$md) {
				max-height: 100%;
			}
		}

		&-img--1 {
			transition: .5s .5s;
		}

		&-img--2 {
			transition: .5s .55s;
		}

		&-img--3 {
			transition: .5s .6s;
		}

		&-img--4 {
			transition: .5s .65s;
		}

		&-img--5 {
			transition: .5s .7s;
		}

		&-img--6 {
			transition: .5s .75s;
		}

		&--history {
			background: linear-gradient(251.61deg, #FF9871 0%, rgba(255, 152, 113, 0) 101.54%), #FCB23C;
			color: #963109;
		}

		&--history &-img--1 {
			bottom: 0;
			right: 0;
			--hide-transform: translateX(80px);

		}

		&--future {
			background: linear-gradient(239.27deg, #FF9EC1 23.78%, #FF9EC1 68.64%);
			color: #951342;
		}

		&--future &-img--1 {
			bottom: 0;
			right: 0;
			--hide-transform: translateX(80px);

		}

		&--future &-picture {
			@media (max-width:$md) {
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0));
			}
		}

		&--flowers {
			background: linear-gradient(74.44deg, #4FB400 -26.7%, #00B4A9 100%);
			color: #fff;
		}

		&--flowers &-img--1 {
			bottom: 0;
			right: 0;
			transform-origin: bottom;
			--hide-transform: translateX(80px) rotateZ(15deg);

		}

		&--ussr {
			background: #8380FE;
			color: #fff;
		}

		&--ussr &-img--1 {
			bottom: 0;
			right: 0;
			transform-origin: bottom;
			--hide-transform: translateX(80px);

		}

		&--ussr &-img--2 {
			top: 42px;
			right: 320px;

			@media (max-width:$sm) {
				right: auto;
				left: 20px;
			}
		}

		&--ussr &-picture {
			@media (max-width:$md) {
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0));
			}
		}

		&--mean {
			background: linear-gradient(70.69deg, #2859D8 0%, #28CDD8 100%);
			color: #fff;
		}

		&--mean &-img--1 {
			bottom: 0;
			right: 0;

			@media (max-width:$md) {
				max-height: calc(100% + 80px);
				height: calc(100% + 80px);
				bottom: -80px;
				object-fit: cover;
			}

			@media (max-width:$xs) {
				max-height: calc(100% + 40px);
				height: calc(100% + 40px);
				bottom: -40px;
			}
		}

		&--mean &-img--2 {
			bottom: 50px;
			right: 75px;
			--hide-transform: translateY(-20px);

			@media (max-width:$md) {
				right: 20px;
				bottom: 24px;
				max-width: calc(100% - 80px);
			}
		}

		&--mean &-img--3 {
			left: 45%;
			top: 12px;

			@media (max-width:$md) {
				display: none;
			}
		}

		&--mean &-img--4 {
			left: calc(50% + 220px);
			top: 32px;

			@media (max-width:$md) {
				display: none;
			}
		}

		&--mean &-picture {
			@media (max-width:$md) {
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0));
			}
		}

		&--home {
			background: url("../img/fact-home-bg.jpg") no-repeat center / cover;

			@media (max-width:$md) {
				background: #98FEFF;
			}
		}

		&--home &-picture {
			@media (max-width:$md) {
				background: url("../img/fact-home-bg.jpg") no-repeat center / cover;
			}
		}

		&--home &-img--1 {
			bottom: 52px;
			right: 110px;

			@media (max-width:$sm) {
				right: auto;
				left: calc(50% - 166px);
				bottom: 68px;
			}
		}

		&--film {
			background: #FEEA80;
		}

		&--film &-img--1 {
			top: 38px;
			right: 235px;
			--hide-transform: translateX(-20px);

			@media (max-width:$md) {
				top: 8px;
				right: calc(50% - 100px);
			}
		}

		&--film &-img--2 {
			top: 63px;
			right: -24px;
			--hide-transform: translateX(20px);

			@media (max-width:$md) {
				top: 18px;
				right: calc(50% - 300px);
			}
		}

		&--film &-img--3 {
			top: 337px;
			right: 135px;

			@media (max-width:$md) {
				display: none;
			}
		}

		&--film &-img--4 {
			top: 281px;
			right: 60px;

			@media (max-width:$md) {
				display: none;
			}
		}

		&--film &-img--5 {
			right: 0;
			bottom: 0;

			@media (max-width:$md) {
				display: none;
			}
		}

		&--tree {
			background: linear-gradient(239.27deg, #FF9EC1 23.78%, rgba(255, 158, 193, 0) 68.64%), #D23F3F;
			color: #fff;
		}

		&--tree &-img--1 {
			right: 20px;
			bottom: 30px;

			@media (max-width:$md) {
				left: calc(50% - 100px);
				right: auto;
				bottom: 0;
			}
		}

		&--tree &-img--2 {
			top: 80px;
			right: 560px;

			@media (max-width:$md) {
				left: 8%;
			}

		}

		&--tree &-img--3 {
			top: 270px;
			right: 456px;

			@media (max-width:$md) {
				left: 22%;
			}
		}

		&--tree &-img--4 {
			bottom: 105px;
			right: 285px;

			@media (max-width:$md) {
				bottom: 50px;
			}
		}

		&--tree &-img--5 {
			top: 120px;
			right: 203px;
		}

		&--tree &-img--6 {
			bottom: 282px;
			right: 200px;

			@media (max-width:$md) {
				right: 100px;
			}
		}

		&--biggest {
			background: #FFFFFF;
		}

		&--biggest &-img--1 {
			right: 0;
			top: 0;
			--hide-transform: translateY(-20px);
		}

		&--biggest &-img--2 {
			bottom: 0;
			right: 94px;

			@media (max-width:$md) {
				left: calc(50% - 100px);
			}
		}

		&--biggest &-img--3 {
			right: 0;
			bottom: 0;
		}

		&--biggest &-picture {
			@media (max-width:$md) {
				background: linear-gradient(0deg, var(--color-primary-20), rgba(0, 0, 0, 0));
			}
		}

		&--europe {
			background: linear-gradient(247.32deg, rgba(254, 242, 60, 0.6) 33.13%, rgba(254, 242, 60, 0) 68.06%), #AC2AA7;
			color: #fff;
		}

		&--europe &-img--1 {
			top: 0;
			right: 126px;
			--hide-transform: translateY(-20px);

			@media (max-width:$md) {
				right: auto;
				left: calc(50% - 120px);
			}
		}



	}

	.swiper-slide:not(.swiper-slide-active) &-item-sign,
	.swiper-slide:not(.swiper-slide-active) &-item-title,
	.swiper-slide:not(.swiper-slide-active) &-item-img,
	.swiper-slide:not(.swiper-slide-active) &-item-desc {
		opacity: 0;
		transform: var(--hide-transform);
	}

	&-btn {
		--size: 80px;
		font-size: 28px;
		position: absolute;
		top: calc(50% - 40px);
		z-index: 3;

		@media(max-width:$lg) {
			--size: 60px;
			top: calc(50% - 30px);
			font-size: 20px;
		}

		@media (max-width:$md) {
			--size: 40px;
			top: calc(400px - 40px - 20px);
			font-size: 16px;
		}
	}

	&-prev {
		left: -60px;

		@media(max-width:$lg) {
			left: -10px;
		}

		@media (max-width:$md) {
			left: 10px;
		}
	}

	&-next {
		right: -60px;

		@media(max-width:$lg) {
			right: -10px;
		}

		@media (max-width:$md) {
			right: 10px;
		}
	}
}

.greeting {
	padding: 100px 15px 60px;

	@media (max-width:$xs) {
		padding-top: 40px;
		padding-bottom: 20px;
	}

	&-title {
		margin-bottom: 48px;

		@media (max-width:$xs) {
			margin-bottom: 20px;
			font-size: 28px;
		}
	}

	&-desc {
		font-size: 18px;
		line-height: 1.5;
		text-align: center;

		@media (max-width:$md) {
			font-size: 16px;

			br {
				display: none;
			}
		}

		@media (max-width:$xs) {
			font-size: 15px;
		}
	}
}

.feedback {

	@media(max-width:$lg) {
		padding-left: 15px;
		padding-right: 15px;
	}

	@media (max-width:$md) {
		padding: 0;
	}

	@media (max-width:$sm) {
		order: 10;
	}

	&-block {
		background: #F2F2FF;
		border-radius: 60px;
		padding: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		@media (max-width:$md) {
			border-radius: 0;
			padding-left: 15px;
			padding-right: 15px;
			overflow: hidden;
		}

		@media (max-width:$sm) {
			padding-top: 24px;
			padding-bottom: 24px;
		}
	}

	&-title {
		margin-bottom: 32px;

	}

	&-desc {
		text-align: center;
		font-size: 18px;
		line-height: 1.5;
		margin-bottom: 45px;
		position: relative;

		@media (max-width:$md) {
			font-size: 14px;

		}

		@media (max-width:$sm) {
			br {
				display: none;
			}
		}

		&:before {
			content: '';
			width: 57px;
			height: 97px;
			position: absolute;
			left: -45px;
			bottom: -85px;
			background: url("../img/feedback-arrow-1.png") no-repeat center / contain;

			@media (max-width:$md) {
				display: none;
			}
		}

		&::after {
			content: '';
			width: 93px;
			height: 105px;
			position: absolute;
			right: -60px;
			bottom: -95px;
			background: url("../img/feedback-arrow-2.png") no-repeat center / contain;

			@media (max-width:$md) {
				display: none;
			}
		}
	}

	&-field {
		width: 410px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		z-index: 2;

		textarea {
			height: 248px;
		}
	}

	&-img {
		position: absolute;
		pointer-events: none;

		@media (max-width:$sm) {
			display: none;
		}

		&--1 {
			left: 67px;
			bottom: 213px;
			animation: fly 6s ease infinite both;
		}

		&--2 {
			right: 102px;
			bottom: 212px;
			animation: fly 6s 2s ease infinite both;
		}
	}
}

.review {
	padding-left: 0;
	padding-right: 0;
	padding-top: 80px;
	padding-bottom: 100px;

	@media (max-width:$xs) {
		padding-top: 20px;
		padding-bottom: 40px;
	}

	&-title {
		margin-bottom: 80px;

		@media (max-width:$xs) {
			margin-bottom: 32px;
		}
	}

	&-slider {
		width: 100%;

		.swiper-wrapper {
			transition-timing-function: linear;
		}
	}

	&-item {
		background: #FFDDDD;
		border-radius: 60px;
		padding: 48px 32px;
		width: 394px;

		@media (max-width:$xs) {
			width: 300px;
			border-radius: 32px;
			padding: 32px 20px;
		}
	}
}

.footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 15px;
	padding-right: 15px;

	img {
		&:nth-of-type(1) {
			animation: fly 6s ease infinite both;
		}

		&:nth-of-type(3) {
			animation: fly 6s 2s ease infinite both;
		}
	}

	@media(max-width:$lg) {
		display: none;

	}
}


@keyframes fly {
	50% {
		transform: translateY(-25px);
	}
}

.dialog-success {
	border-radius: 32px;
	background: #FF9EC1;
	width: 500px;
	max-width: 100%;
	margin-top: 100px;

	@media(max-width:$sm) {
		border-radius: 20px;
	}

	&__img {
		background: radial-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0) 60%);

		img {
			margin-top: -20px;
			margin-left: -120px;
			max-width: calc(100% + 240px);
			width: calc(100% + 240px);
			display: inline-block;

			@media(max-width:$sm) {
				margin-left: -50px;
				max-width: calc(100% + 100px);
				width: calc(100% + 100px);
			}
		}
	}

	&__content {
		color: #fff;
		padding: 45px;

		@media(max-width:$sm) {
			padding: 20px;
		}
	}

	&__sign {
		font-weight: 900;
		font-size: 24px;
		text-align: center;

		@media(max-width:$sm) {
			font-size: 18px;
		}
	}

	&__title {
		font-weight: 900;
		font-size: 48px;
		text-align: center;
		text-transform: uppercase;

		@media(max-width:$sm) {
			font-size: 32px;

		}
	}

	&__subtitle {
		font-weight: 900;
		font-size: 24px;
		text-align: center;

		@media(max-width:$sm) {
			font-size: 18px;
		}
	}

	&__btn {
		color: var(--color-text);
		width: 100%;
		margin-top: 30px;
	}
}