// out:false


.link-hover {
  cursor               : pointer;
  text-underline-offset: 4px;

  @media(hover) {
    &:hover {
      text-decoration: underline;
      opacity        : 1;

    }
  }
}

.link-hover-wrapper {
  cursor: pointer;

  @media(hover) {
    &:hover {
      .link-hover {
        text-decoration: underline;
        opacity        : 1;
      }

    }
  }
}